<template>
    <div class="card-body">

      <div class="row">
        <nav class="nav nav-pills nav-fill mt-2">
          <router-link to="/admin/users" class="nav-item nav-link active" >{{$t('menu.users')}}</router-link>
          <router-link to="/admin/companies" class="nav-item nav-link ">{{$t('menu.companies')}}</router-link>
        </nav>
      </div>
      <div class="row">

          <div class="col-md-12">
            <h5 class="my-3">Seznam registrovanych pro ucely administrace</h5>

              <table class="table table-stripped">
                  <tr>
                      <th>ID</th>
                      <th>email</th>
                      <th>jmeno</th>
                      <th>telefon</th>
                      <th>adresa</th>
                      <th>Firma</th>
                      <th>role</th>
                      <th>potvrdil registraci</th>
                      <td></td>
                  </tr>
                  <tr v-for="u in users" :key=u.id :class="u.id==me.id ?   'bg-warning':''">
                      <td>#{{u.id}}</td>
                      <td>{{u.email}}</td>
                      <td>{{u.name}}</td>
                      <td>{{u.tel}}</td>
                      <td>{{u.address}}</td>
                      <td><b>{{ (u.company ? u.company.name : null) || u.company_name  }}</b></td>
                      <td>{{u.role}}</td>
                      <td>{{u.confirmed_at | dateTime}}</td>
                      <td>
                        <button @click.prevent="destroyUser(u)" class="btn btn-sm btn-danger">{{$t('admin.delete')}}</button>
                      </td>
                  </tr>
              </table>

          </div>
      </div>
    </div>
</template>


<script>

import axiosApiInstance from "@/utils/api";

const axios = require("axios");
axios.defaults.withCredentials = true;

import userMixin from "@/mixins/userMixin";

export default {
    name: 'Users',
    mixins:[userMixin],
    data() {
        return {
            users: []
        }
    },
    computed: {
        me() {
            if (this.$store.getters.me)
                return this.$store.getters.me;
            else
                return {}
        }
    },

    mounted() {
        axios.get(process.env.VUE_APP_API_ROOT_URL + "/user").then((result) => {

            if (result.status ==200){
                this.users = result.data
            }
        }).catch((err) => {
            console.err(err)
        });
    },
    methods: {
      destroyUser(u){

        if (!confirm(this.$t('groups.remove-confirmation-cokoliv'))) return;

        axiosApiInstance.delete(`/user/${u.id}`).then((result) => {

            if (result.status >=200 && result.status<300){
              for(var i in this.users){
                if (this.users[i].id == u.id){
                  this.users.splice(i, 1)
                }
              }
            }
        }).catch((err) => {
            console.error(err)
        });

      }

    },
}
</script>


<style  scoped>
table{ background: white}
</style>
